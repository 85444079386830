import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-paupi-steckbrief',
  templateUrl: './paupi-steckbrief.component.html',
  styleUrls: ['./paupi-steckbrief.component.css']
})
export class PaupiSteckbriefComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
