<mat-card>
    <mat-card-title>
        Pauline Speckmann
    </mat-card-title>

    Auf dieser Seite werden alle interessanten Hausarbeiten, 
    Abschlussarbeiten, Beiträge, Artikel und Veröffentlichungen von Pauline Speckmann präsentiert.

    <br>
    In naher Zukunft folgt noch eine Informatik-Bachelorarbeit.
</mat-card>


<mat-card>
    <mat-card-title>
        Seminararbeiten
    </mat-card-title>

    <mat-grid-list cols="3" rowHeight="2:1">
        <a routerLink="/extremely-fast-decsision-tree">
            <mat-grid-tile>
                <mat-grid-tile-header>
                    Extremely Fast Decision Trees
                </mat-grid-tile-header>
                <p>
                    Eine Seminararbeit, die im Rahmen des Informatik-Proseminars <i>Maschinelles Lernen</i> an der 
                    TU Dortmund geschrieben wurde.
                    <br>
                    Es werden verschiedene Ansätze von Entscheidungsbäumen erläutert und schließlich wird das Konzept des <i>Extremely Fast Decision Trees</i> vorgestellt.
                </p>
            </mat-grid-tile>
        </a>
        <a routerLink="/mills-utilitarismus">
            <mat-grid-tile>
                <mat-grid-tile-header>
                    Mills Utilitarismus
                </mat-grid-tile-header>
                <p>
                    Eine Seminararbeit, die im Rahmen des Philosophie-Seminars <i>Präsentieren und Diskutieren: Klassiker der Ethik</i> an der 
                    TU Dortmund geschrieben wurde.
                    <br>
                    Es wird zuerst eine Übersicht über John St. Mill als Person gegeben, bevor sein Ansatz des Utilitarismus ausgeführt und diskutiert wird.
                </p>
            </mat-grid-tile>
        </a>
      </mat-grid-list>
</mat-card>
