import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {MatTabsModule} from '@angular/material/tabs';
import {MatCardModule} from '@angular/material/card';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatGridListModule} from '@angular/material/grid-list'; 

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ImpressumComponent } from './impressum/impressum.component';
import { HomeComponent } from './home/home.component';
import { SonstigesComponent } from './sonstiges/sonstiges.component';

import { PinaComponent } from './pina/pina.component';
import { PinaSteckbriefComponent } from './pina-steckbrief/pina-steckbrief.component';

import { PaupiComponent } from './paupi/paupi.component';
import { PaupiSteckbriefComponent } from './paupi-steckbrief/paupi-steckbrief.component';
import { ExtremelyFastDecsisionTreeComponent } from './paupi/PaupisArbeiten/extremely-fast-decsision-tree/extremely-fast-decsision-tree.component';
import { MillsUtilitarismusComponent } from './paupi/PaupisArbeiten/mills-utilitarismus/mills-utilitarismus.component';
import { DomaenenspezifischeSpracheComponent } from './pina/PinasArbeiten/domaenenspezifische-sprache/domaenenspezifische-sprache.component';
import { ZauberwuerfelComponent } from './pina/PinasArbeiten/zauberwuerfel/zauberwuerfel.component';


@NgModule({
  declarations: [
    AppComponent,
    ImpressumComponent,
    PinaComponent,
    PaupiComponent,
    HomeComponent,
    PinaSteckbriefComponent,
    PaupiSteckbriefComponent,
    SonstigesComponent,
    ExtremelyFastDecsisionTreeComponent,
    MillsUtilitarismusComponent,
    DomaenenspezifischeSpracheComponent,
    ZauberwuerfelComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatTabsModule,
    MatCardModule,
    MatSidenavModule,
    MatListModule,
    MatGridListModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
