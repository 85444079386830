<br>
<mat-card>
    <mat-card-header>
        <mat-card-title>
            Extremely Fast Decision Trees
        </mat-card-title>
        <mat-card-subtitle>
            Seminararbeit
        </mat-card-subtitle>
    </mat-card-header>
    <iframe src="assets/pdf/Seminararbeit_ExtremelyFastDecisionTrees.pdf" height="350em" width="60%" alt="PDF - Extremely Fast Decision Trees"></iframe>
    <mat-card-content>
        <p>
            Eine Seminararbeit, die im Rahmen des Informatik-Proseminars <i>Maschinelles Lernen</i> an der 
            TU Dortmund geschrieben wurde.
            <br>
            Es werden verschiedene Ansätze von Entscheidungsbäumen erläutert und schließlich wird das Konzept des <i>Extremely Fast Decision Trees</i> vorgestellt.
        </p>
    </mat-card-content>
</mat-card>