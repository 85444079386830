<br>
<mat-card>
    <mat-card-header>
        <mat-card-title>
            Domänenspezifische Sprachen
        </mat-card-title>
        <mat-card-subtitle>
            Seminararbeit
        </mat-card-subtitle>
    </mat-card-header>
    <iframe src="assets/pdf/Proseminar_DSL.pdf" height="350em" width="60%" alt="PDF - Domänenspezifische Sprachen"></iframe>
    <mat-card-content>
        <p>
            Eine Seminararbeit, die im Rahmen des Proseminars <i>Geschichte der Programmierung</i> an der 
            TU Dortmund geschrieben wurde.
            <br>
            Es werden domänenspezifische Sprachen grundlegend erklärt und eine domänenspezifische Sprache selbst implementiert.
        </p>
    </mat-card-content>
</mat-card>