<!DOCTYPE html>
<html>
<body>


 <!-- <mat-tab-group mat-align-tabs="start" >
    
     
      <mat-tab label="Home">
        <app-home></app-home>
      </mat-tab>


      <mat-tab label="Pina"  >
        <app-pina></app-pina>
      </mat-tab>



      <mat-tab label="Paupi"> 
        <app-paupi></app-paupi>
      </mat-tab> 
        
      <mat-tab label="Impressum"> 
        <app-impressum></app-impressum>
      </mat-tab>

  </mat-tab-group> 
  <router-outlet></router-outlet> -->


 <mat-sidenav-container>
    <mat-sidenav #sidenav mode="side" opened="true">
      <mat-nav-list>
        <a mat-list-item id="navbaritem" routerLink="/home" id="sidenavLogo"> 
          <img src="./assets/img/PinaupicationsLogo.png" alt="Pinaupications Logo" width="80%" />
        </a>
        <a mat-list-item id="navbaritem" routerLink="/home" routerLinkActive="active"> Home </a>
        <a mat-list-item id="navbaritem" routerLink='/pina' routerLinkActive="active"> Pina </a>
        <a mat-list-item id="navbaritem" routerLink='/paupi' routerLinkActive="active"> Paupi </a>
        <a mat-list-item id="navbaritem" routerLink='/sonstiges' routerLinkActive="active"> Sonstiges </a>
        <a mat-list-item id="navbaritem" routerLink='/impressum' routerLinkActive="active"> Impressum </a> 
  
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <div style="height: 88vh;">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>




</body>
</html>