import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ImpressumComponent } from './impressum/impressum.component';
import { HomeComponent } from './home/home.component';
import { SonstigesComponent } from './sonstiges/sonstiges.component'

import { PinaComponent } from './pina/pina.component';
import { PinaSteckbriefComponent } from './pina-steckbrief/pina-steckbrief.component';
import { DomaenenspezifischeSpracheComponent } from './pina/PinasArbeiten/domaenenspezifische-sprache/domaenenspezifische-sprache.component';

import { PaupiComponent } from './paupi/paupi.component';
import { PaupiSteckbriefComponent } from './paupi-steckbrief/paupi-steckbrief.component';
import { ExtremelyFastDecsisionTreeComponent } from './paupi/PaupisArbeiten/extremely-fast-decsision-tree/extremely-fast-decsision-tree.component';
import { MillsUtilitarismusComponent } from './paupi/PaupisArbeiten/mills-utilitarismus/mills-utilitarismus.component';


/** Routing-Komponente
 * sollte recht simpel bleiben und von daher selbsterklärend sein
 * -> keine Kommentare nötig
 */

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'impressum', component: ImpressumComponent },
  { path: 'sonstiges', component: SonstigesComponent },

  { path: 'pina', component: PinaComponent },
  { path: 'pinasteckbrief', component: PinaSteckbriefComponent },
  { path: 'domaenenspezifische-sprache', component: DomaenenspezifischeSpracheComponent },

  { path: 'paupi', component: PaupiComponent },
  { path: 'paupisteckbrief', component: PaupiSteckbriefComponent },
  { path: 'extremely-fast-decsision-tree', component: ExtremelyFastDecsisionTreeComponent },
  { path: 'mills-utilitarismus', component: MillsUtilitarismusComponent },
  
  { path: '**', component: HomeComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' }
  ];

@NgModule({
  declarations: [],
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})

export class AppRoutingModule { }
