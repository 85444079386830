import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pina-steckbrief',
  templateUrl: './pina-steckbrief.component.html',
  styleUrls: ['./pina-steckbrief.component.css']
})
export class PinaSteckbriefComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
