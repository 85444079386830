        
        <img src="assets/img/PinaupicationsLogo.png" alt="Pinaupications Logo"  width= "30%"/>

        <h1>Das ist Pinas und Paupis Publications-Website!</h1>
        <p>Aktuell noch work in progress! </p>
        
        
        
        
        
         <p> Wir werden hier eine ansehnliche Website erstellen und auf dieser unsere Haus- und Abschlussarbeiten hochladen, die wir für die Uni erstellt haben.
          
          Aktuell kann man sich auf zwei Seminararbeiten im Bereich Informatik, zwei Bachelorarbeiten im Bereich Informatik und eine Hausarbeit im Themenbereich der Philosophie freuen.
          </p>
