import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mills-utilitarismus',
  templateUrl: './mills-utilitarismus.component.html',
  styleUrls: ['./mills-utilitarismus.component.css']
})
export class MillsUtilitarismusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
