<mat-card>
    <mat-card-title>
        Pina Kolling
    </mat-card-title>

    Auf dieser Seite werden alle interessanten Hausarbeiten, 
    Abschlussarbeiten, Beiträge, Artikel und Veröffentlichungen von Pina Kolling präsentiert.

    <br>
    Aktuell umfasst das leider nur eine Seminararbeit.
    Es folgt bald aber meine Bachelorarbeit im Fach Informatik.
</mat-card>


<mat-card>
    <mat-card-title>
        Seminararbeiten
    </mat-card-title>

    <mat-grid-list cols="3" rowHeight="2:1">
        <a routerLink="/domaenenspezifische-sprache">
            <mat-grid-tile>
                <mat-grid-tile-header>
                    Domänenspezifische Sprachen
                </mat-grid-tile-header>
                <p>
                    Eine Seminararbeit, die im Rahmen des Proseminars <i>Geschichte der Programmierung</i> an der 
                    TU Dortmund geschrieben wurde.
                    <br>
                    Es werden domänenspezifische Sprachen grundlegend erklärt und eine domänenspezifische Sprache selbst implementiert.
                </p>
            </mat-grid-tile>
        </a>
      </mat-grid-list>
</mat-card>