import { Component, OnInit } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
/** import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component'; */

@Component({
  selector: 'app-pina',
  templateUrl: './pina.component.html',
  styleUrls: ['./pina.component.css']
})

/* @NgModule({
 declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule 
  ],
  providers: [],
  bootstrap: [AppComponent]
}) */

export class PinaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
