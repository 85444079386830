<br>
<mat-card>
    <mat-card-header>
        <mat-card-title>
            Mills Utilitarismus
        </mat-card-title>
        <mat-card-subtitle>
            Seminararbeit
        </mat-card-subtitle>
    </mat-card-header>
    <iframe src="assets/pdf/Seminararbeit_Mills_Utilitarismus.pdf" height="350em" width="60%" alt="PDF - Mills Utilitarismus"></iframe>
    <mat-card-content>
        <p>
            Eine Seminararbeit, die im Rahmen des Philosophie-Seminars <i>Präsentieren und Diskutieren: Klassiker der Ethik</i> an der 
            TU Dortmund geschrieben wurde.
            <br>
            Es wird zuerst eine Übersicht über John St. Mill als Person gegeben, bevor sein Ansatz des Utilitarismus ausgeführt und diskutiert wird.
        </p>
    </mat-card-content>
</mat-card>